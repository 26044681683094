import { Pipe, PipeTransform } from '@angular/core';
import { PriceScaleItem } from '@lobos/common';

@Pipe({
  name: 'getPriceFromPriceScale',
})
export class GetPriceFromPriceScalePipe implements PipeTransform {
  transform(scale: PriceScaleItem[], quantity: number): number {
    let price = scale[0].decPriceNet;
    const sortedScale = [...scale].sort((a, b) => a.decQuantityFrom - b.decQuantityFrom);
    sortedScale.forEach((item) => {
      if (item.decQuantityFrom <= quantity) {
        price = item.decPriceNet;
      }
    });
    return price;
  }
}
