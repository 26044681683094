<ng-container *transloco="let t; scope: 'header'">
  <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, cart: activeCart$ | async, items: activeCartItems$ | async } as context">
    <div *ngIf="!mobile" appClickOutside (clickOutside)="close()">
      <button
        (click)="open()"
        class="js-show-info-btn p-2 leading-none rounded-default group hover:bg-primary-bright transition-all duration-200 relative"
      >
        <span
          class="ifont ifont--cart text-3xl text-primary group-hover:text-primary-dark transition-all duration-200 pointer-events-none"
        ></span>
        <span
          class="bg-gela-black border-2 border-white text-white text-cart font-montserrat font-bold rounded-full px-5px min-w-22px h-22px flex items-center justify-center absolute top-2 -right-1.5 pointer-events-none"
          >{{ (context.items! | cartItemIsMaster).length }}</span
        >
      </button>
      <div
        [class.hidden]="!showFlyout"
        class="js-info absolute bg-white border border-primary-light rounded-flyout shadow-flyout translate-y-0 md:top-full -right-3 z-50 overflow-hidden"
      >
        <div class="max-h-500px md:max-h-flyout-header md:min-h-flyout-header p-3" *ngIf="context.cart as cart">
          <div class="md:w-614px bg-white" [appLoading]="isLoading$ | async">
            <div *ngIf="context.isLoggedIn" class="sm:px-6 sm:py-3 fixed left-0 top-3 w-full z-20 bg-white">
              <div class="flex justify-between mb-4">
                <p class="font-montserrat font-bold text-lg">{{ t("header.carts.title") }}</p>
                <button (click)="close()" class="js-close-header-flyout transition-all duration-200 hover:text-primary-primary">
                  <span class="ifont ifont--close text-3xl pointer-events-none"></span>
                </button>
              </div>
              <div class="flex items-center justify-between" *ngIf="context.cart as cart">
                <div class="min-w-5/12 inline-block">
                  <div class="js-select-wrap relative z-20 bg-primary-light text-center rounded-button font-normal">
                    <app-button-dropdown
                      [value]="cart.lngOrderID.toString()"
                      (valueChange)="changeCart($event)"
                      *ngIf="carts$ | async as carts"
                      [options]="carts"
                      [showValue]="true"
                      [hasMaxHeight]="true"
                    ></app-button-dropdown>
                  </div>
                </div>
                <div class="flex space-x-4 items-start ml-6">
                  <a (click)="editCart(cart.lngOrderID)" class="text-sm font-bold hover:text-primary leading-tight">
                    <span class="ifont ifont--settings text-icon"></span>
                  </a>
                  <button (click)="addCart()" class="js-open-cart-modal text-left text-sm font-bold hover:text-primary leading-tight">
                    <span class="ifont ifont--add text-icon pointer-events-none"></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="h-32" *ngIf="context.isLoggedIn">&nbsp;</div>
            <ng-container *ngIf="context.items?.length; else empty">
              <div
                class="sm:pl-3 pr-3 md:mb-12 scrollbar scrollbar--cart overflow-y-auto overflow-x-hidden max-h-cart md:max-h-cart-md pb-20"
              >
                <table class="w-full my-4 md:my-0">
                  <thead class="bg-primary-bright hidden md:table-header-group">
                    <tr>
                      <td class="p-2 text-xs md:text-sm font-montserrat font-bold">{{ t("header.carts.amount") }}</td>
                      <td class="p-2 text-xs md:text-sm font-montserrat font-bold">{{ t("header.carts.article") }}</td>
                      <td class="p-2 text-xs md:text-sm font-montserrat font-bold">{{ t("header.carts.total") }}</td>
                      <td class="p-2 text-xs md:text-sm font-montserrat font-bold"></td>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-primary-light">
                    <tr *ngFor="let item of context.items! | cartItemIsMaster" class="w-full flex md:table-row relative">
                      <td class="p-2 basis-2/12 align-middle text-xs md:text-sm">
                        <p>{{ item.decQuantityOrdered }}</p>
                      </td>
                      <td class="p-2 basis-1/2 align-middle text-xs md:text-sm">
                        <p class="hyphens-auto">{{ item.oArticle.sDescription || item.sArticleName }}</p>
                      </td>
                      <td class="p-2 basis-4/12 text-right lg:text-left align-middle text-xs md:text-sm">
                        <p class="font-montserrat font-bold my-auto">
                          {{
                            (context.isLoggedIn
                              ? item.decItemNetAmountFC
                              : (item.decPrice * item.decQuantityOrdered) / item.oArticle.lngSalesPriceUnit
                            ) | translocoCurrency
                          }}
                        </p>
                      </td>
                      <td class="pl-2 py-1 lg:p-2 basis-1/12 pr-0 align-middle text-xs md:text-sm">
                        <div class="flex items-center justify-end">
                          <div class="relative mr-0.5 last:mr-0 leading-none">
                            <app-tooltip [text]="t('header.carts.tooltip.remove')">
                              <button class="js-show-flyout js-open-modal-delete group" (click)="removeItem(item)">
                                <span
                                  class="ifont ifont--delete text-icon text-primary group-hover:text-gela-black transition-all duration-200 pointer-events-none"
                                ></span>
                              </button>
                            </app-tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="w-full bg-white bottom-0 left-0 fixed px-6 pb-4 flex flex-col">
                <div class="flex border-t border-gela-black flex-col my-4 pt-2">
                  <p class="text-small font-montserrat font-bold flex justify-between">
                    <span>
                      {{ t("header.carts.total") }}
                      <small class="ml-1">{{ t("header.carts.total-info") }}</small>
                    </span>
                    <span> {{ cart.decTotalOrderNetAmount | translocoCurrency }}</span>
                  </p>
                </div>
                <div class="w-auto self-start">
                  <a
                    *ngIf="context.isLoggedIn"
                    (click)="close()"
                    [routerLink]="['checkout/cart' | localizeUrlPathAsync | async, cart.lngOrderID]"
                    class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold px-6 py-2.5 self-center rounded-button transition-all duration-200 inline-block"
                    [title]="t('header.carts.checkout')"
                  >
                    {{ t("header.carts.checkout") }}
                  </a>
                  <button
                    *ngIf="!context.isLoggedIn"
                    (click)="openLoginModal()"
                    class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold px-6 py-2.5 self-center rounded-button transition-all duration-200 inline-block"
                    [title]="t('header.carts.login')"
                  >
                    {{ t("header.carts.login") }}
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-template #empty>
              <div class="sm:w-350px p-3">
                <p class="font-montserrat font-medium text-small">{{ t("header.carts.empty-message") }}</p>
              </div>
              <div class="w-auto self-start pl-3">
                <a
                  *ngIf="context.isLoggedIn"
                  (click)="close()"
                  [routerLink]="['checkout/cart' | localizeUrlPathAsync | async, cart.lngOrderID]"
                  class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold px-6 py-2.5 self-center rounded-button transition-all duration-200 inline-block"
                  [title]="t('header.carts.checkout')"
                >
                  {{ t("header.carts.checkout") }}
                </a>
                <button
                  *ngIf="!context.isLoggedIn"
                  (click)="openLoginModal()"
                  class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold px-6 py-2.5 self-center rounded-button transition-all duration-200 inline-block"
                  [title]="t('header.carts.login')"
                >
                  {{ t("header.carts.login") }}
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="js-mobile-cart min-h-full bg-white px-5 pt-5" *ngIf="mobile">
      <ng-container *ngIf="context.cart as cart">
        <div class="portrait:mt-11" [appLoading]="isLoading$ | async">
          <div class="w-full z-20">
            <div class="flex justify-between mb-4">
              <p class="text-xl font-montserrat font-bold">{{ t("header.carts.title") }}</p>
              <div class="flex space-x-4 items-start">
                <a (click)="editCart(cart.lngOrderID)" class="text-sm font-bold hover:text-primary leading-tight">
                  <span class="ifont ifont--settings text-icon"></span>
                </a>
                <button (click)="addCart()" class="js-open-cart-modal text-left text-sm font-bold hover:text-primary leading-tight">
                  <span class="ifont ifont--add text-icon pointer-events-none"></span>
                </button>
              </div>
            </div>
            <div class="w-full inline-block">
              <app-button-dropdown
                [value]="cart.lngOrderID.toString()"
                (valueChange)="changeCart($event)"
                *ngIf="carts$ | async as carts"
                [options]="carts"
                [showValue]="true"
                [hasMaxHeight]="true"
              ></app-button-dropdown>
            </div>
          </div>
          <ng-container *ngIf="context.items?.length; else empty">
            <div
              *ngIf="context.items as items"
              class="mobile-article-container mb-0 portrait:md:mb-12 xl:pt-6 mt-6 divide-y divide-primary-light bg-white overflow-y-auto overflow-x-hidden"
            >
              <table class="bg-white w-full">
                <tbody class="divide-y divide-primary-light">
                  <tr *ngFor="let item of items | cartItemIsMaster" class="w-full flex md:table-row relative">
                    <td class="p-2 basis-2/12 align-middle text-xs md:text-sm">
                      <p>{{ item.decQuantityOrdered }}</p>
                    </td>
                    <td class="p-2 basis-1/2 align-middle text-xs md:text-sm">
                      <p class="hyphens-auto">{{ item.oArticle.sDescription || item.sArticleName }}</p>
                    </td>
                    <td class="p-2 basis-4/12 text-right lg:text-left align-middle text-xs md:text-sm">
                      <p class="font-montserrat font-bold my-auto">
                        {{ (context.isLoggedIn ? item.decItemNetAmountFC : item.decPrice * item.decQuantityOrdered) | translocoCurrency }}
                      </p>
                    </td>
                    <td class="pl-2 py-1 lg:p-2 basis-2/12 pr-0 align-middle text-xs md:text-sm">
                      <div class="flex items-center justify-end">
                        <div class="relative mr-0.5 last:mr-0 leading-none">
                          <app-tooltip [text]="t('header.carts.tooltip.remove')">
                            <button class="js-show-flyout js-open-modal-delete group" (click)="removeItem(item)">
                              <span
                                class="ifont ifont--delete text-icon text-primary group-hover:text-gela-black transition-all duration-200 pointer-events-none"
                              ></span>
                            </button>
                          </app-tooltip>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="w-full pb-8 flex flex-col">
              <div class="bg-white flex border-t border-gela-black flex-col mb-4 p-2">
                <p class="text-small font-montserrat font-bold flex justify-between">
                  <span>
                    {{ t("header.carts.total") }}
                    <small class="ml-1">{{ t("header.carts.total-info") }}</small>
                  </span>
                  <span> {{ cart.decTotalOrderNetAmount | translocoCurrency }}</span>
                </p>
              </div>
              <div class="w-auto self-start">
                <a
                  *ngIf="context.isLoggedIn"
                  [routerLink]="['checkout/cart' | localizeUrlPathAsync | async, cart.lngOrderID]"
                  class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold px-6 py-2.5 self-center rounded-button transition-all duration-200 inline-block"
                  [title]="t('header.carts.checkout')"
                  (click)="close()"
                >
                  {{ t("header.carts.checkout") }}
                </a>
                <button
                  *ngIf="!context.isLoggedIn"
                  (click)="openLoginModal()"
                  class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold px-6 py-2.5 self-center rounded-button transition-all duration-200 inline-block"
                  [title]="t('header.carts.login')"
                >
                  {{ t("header.carts.login") }}
                </button>
              </div>
            </div>
          </ng-container>
          <ng-template #empty>
            <div class="sm:w-350px p-3 mt-3">
              <p class="font-montserrat font-medium text-small">{{ t("header.carts.empty-message") }}</p>
            </div>
            <div class="w-auto self-start">
              <a
                *ngIf="context.isLoggedIn"
                [routerLink]="['checkout/cart' | localizeUrlPathAsync | async, cart.lngOrderID]"
                class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold px-6 py-2.5 self-center rounded-button transition-all duration-200 inline-block"
                [title]="t('header.carts.checkout')"
                (click)="close()"
              >
                {{ t("header.carts.checkout") }}
              </a>
              <button
                *ngIf="!context.isLoggedIn"
                (click)="openLoginModal()"
                class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold px-6 py-2.5 self-center rounded-button transition-all duration-200 inline-block"
                [title]="t('header.carts.login')"
              >
                {{ t("header.carts.login") }}
              </button>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
